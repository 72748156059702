
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/en/docs",
      function () {
        return require("private-next-pages/en/docs/index.mdx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/en/docs"])
      });
    }
  